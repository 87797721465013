import api from "@/api/api";
import $ from 'jquery'

export default {
  isIterable(obj) {
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  },
  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  },

  getCountdownTime(minutes) {
    return minutes * 60 * 1000
  },
  padStart(value, length, chars) {
    let str = value.toString();
    while (str.length < length) str = chars + str;
    return str;
  },

  chunkArray(array, size) {
    if(Array.isArray(array) && array.length <= size){
      if (array.length === 1) {
        array.push({})
      }

      return [array]
    }
    return [array.slice(0,size), ...this.chunkArray(array.slice(size), size)]
  },

  async downloadDocument(id, type, setLoader = null) {
    if (setLoader && typeof setLoader === 'function') {
      setLoader(true)
    }

    const response = await api.downloadDoc(id, type)

    if (setLoader && typeof setLoader === 'function') {
      setLoader(false)
    }

    if (response && response.data && response.data.result && response.data.result.src) {
      await this.downloadFile(response.data.result.src)
    }
  },

  async downloadPayroll(dateBegin, setLoader = null) {
    if (setLoader && typeof setLoader === 'function') {
      setLoader(true)
    }

    const response = await api.downloadPayroll(document)

    if (setLoader && typeof setLoader === 'function') {
      setLoader(false)
    }

    if (response && response.data && response.data.result && response.data.result.src) {
      await this.downloadFile(response.data.result.src)
    }
  },
  async downloadStudentDocsCompeletyDS(filter) {

    const response = api.getStudentDocsCompeletyDS(filter)

    response.then(res => {
      if (res && res.data && res.data.result && res.data.result.path) {
        this.downloadFile(res.data.result.path)
      }
    })
  },
  async downloadStudentDocsWithSign(id) {

    const response = api.getStudentDocsSign(id)

    response.then(res => {
      if (res && res.data && res.data.result && res.data.result.path) {
        this.downloadFile(res.data.result.path)
      }
    })
  },
  async downloadFile(src) {
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1
      || navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
      let link = window.document.createElement('a')
      link.href = decodeURIComponent(src);

      if (link.download !== undefined){
        link.download = ""
      }

      if (window.document.createEvent) {
        let e = window.document.createEvent('MouseEvents');
        e.initEvent('click' ,true ,true);
        link.dispatchEvent(e);
        return true;
      }
    }

    let query = '?download';

    window.open(decodeURIComponent(src) + query, '_blank');
  },

  addClassToBody(className) {
    $('body').addClass(className)
  },

  removeClassFromBody(className) {
    $('body').removeClass(className)
  },

  addModalOpenClass() {
    this.addClassToBody('modal-open')
  },

  removeModalOpenClass() {
    this.removeClassFromBody('modal-open')
  },
  validateEmail(email) {
    let arEmail = email.trim().split('@')

    if (arEmail[0].length > 30) {
      return false
    }

    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  },
  validatePhone(phone) {
    let phoneRegex = /^(\+?7|8)?\s?(\d{10}|(\(|-)?\d{3}\s?(\)|-)?(\)\s)?\d{3}(\s|-)?\d{2}(\s|-)?\d{2})$/

    return phoneRegex.test(phone.trim());
  },
  validateCode(code) {
    let codeRegex = /^\d{6}$/

    return codeRegex.test(code.trim());
  },
  validatePin(pin, repeatPin) {
    let pinRegex = /^\d{6}$/

    return pinRegex.test(pin.trim()) && pinRegex.test(repeatPin.trim());
  },

  handleValidatePasswordForm(val, config, interFunc, valConfirm) {
    let valid = true;
    let errorMessage = '';
    
    if(config.variables) {
      if (config.variables.passwordMinLengthPolicy) {
        if (valid && val.length < config.variables.passwordMinLengthPolicy) {
          valid = false;
        }
      }
      if (config.variables.passwordMaxLengthPolicy) {
        if (valid && val.length > config.variables.passwordMaxLengthPolicy) {
          valid = false;
        }
      }   
      if (valid && config.variables.passwordPolicyLatinOnly) {
        const expValid = !(new RegExp(`[^A-Za-z1-9${config.variables.passwordSpecialSymbols}]+`).test(val));
        if (!expValid) {
          valid = false;
        }
      }
      if (valid && config.variables.passwordAlphaUpperCasePolicy) {
        const expValid = /[A-Z]+/.test(val);
        if (!expValid) {
          valid = false;
        }
      }
      if (valid && config.variables.passwordAlphaLowerCasePolicy) {
        const expValid = /[a-z]+/.test(val);
        if (!expValid) {
          valid = false;
        }
      }
      if (valid && config.variables.passwordNumberPolicy) {
        const expValid = /[0-9]+/.test(val);
        if (!expValid) {
          valid = false;
        }
      }
      if (valid && config.variables.passwordSpecialSymbols && config.variables.passwordSpecialSymbols.length) {
        const expValid = new RegExp(`[${config.variables.passwordSpecialSymbols}]+`).test(val);
        if (!expValid) {
          valid = false;
        }
      }
    }
    if (valConfirm != undefined && val !== valConfirm) {
      valid = false;
      errorMessage = interFunc('registration.password_confirm_error');
      return { valid, errorMessage } ;
    }
    if (!valid)
      errorMessage = `${interFunc('login.general_policy')} ${interFunc('login.min_password_length')} ${config.variables.passwordMinLengthPolicy} ${interFunc('login.max_password_length')} ${config.variables.passwordMaxLengthPolicy} ${interFunc('login.general_policy_ending')}`;
    return { valid, errorMessage } ;
  },
}