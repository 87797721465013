//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/api/api";
import notification from "@/notification/notification";
import $ from "jquery";
import utils from "@/utils";

export default {
  name: "GetArchiveModal",
  props: {
    filter: Object,
    buildFilter: Function
  },
  computed: {
    idDocuments() {
      return this.$store.getters.getIdDocuments;
    }
  },
  data() {
    return {
      filterModal: {
        sort: 'type',
        format: 'pdf',
        hideLogo: false,
        hideBarcode: false,
        hideDates: false,
        showDepartment: false,
        showLogTitle: false,
        addCheckMark: false,
      },
      activeTab: "all",
      tabs: [
        {
          type: "all",
          name: this.$i18n.t('documents.all'),
        },
        {
          type: 'sign',
          name: this.$i18n.t('documents.signed_wds')
        },
      ],
    }
  },
  methods: {
    hide() {
      $('#modalGetArchive').modal('hide')
    },
    sortButtonsActive(e) {
      if ($(e.target).hasClass('sort')) {
        $('label.sort').removeClass('active')
        $(e.target).addClass('active')
      }
    },
    formatButtonsActive(e) {
      if ($(e.target).hasClass('format')) {
        $('label.format').removeClass('active')
        $(e.target).addClass('active')
      }

    },
    downloadArchive() {
      this.filter.id = this.idDocuments

      const response = api.downloadDocs(this.filter, {
        ...this.filterModal,
        fromExternalEducation: true,
      })

      response.then(res => {
        if (res.data.error && res.data.error === 401) {
          this.$router.push({
            name: 'login'
          })
        }

        if (!res.data.error) {
          notification.information(this.$i18n.t('documents.gen_task_added'))
        }
      })

      this.hide()
    },
    downloadsStudentDocumentsWithSign() {
      utils.downloadStudentDocsCompeletyDS(this.filter)
    },
    setTab(type) {
      this.activeTab = type;
    },
    isCurrentTab(type) {
      if (this.activeTab == type) {
        return true;
      }
      return false;
    },
    isDocumentsAll() {
      if (this.activeTab == "all") {
        return true
      }
      return false
    },
  }
}
