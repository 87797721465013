//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState, mapActions, mapMutations} from 'vuex';
import api from "../api/api";
import $ from 'jquery'
import config from '../../app.config'
import DocMaterial from "./view-material/DocMaterial";
import TextLesson from "./view-material/TextMaterial";
import IspringLesson from "./view-material/IspringMaterial";
import VideoLesson from "./view-material/VideoMaterial";
import AudioLesson from "./view-material/AudioMaterial";
import YoutubeMaterial from "./view-material/YoutubeMaterial";
import PdfMaterial from "./view-material/PdfMaterial";
import LessonHeader from "../headers/LessonHeader";
import RestrictedContent from "@/components/errorBlocks/RestrictedContent";

export default {
  name: 'Lesson',
  components: {
    RestrictedContent,
    LessonHeader,
    PdfMaterial, YoutubeMaterial, AudioLesson, VideoLesson, IspringLesson, TextLesson, DocMaterial},
  metaInfo () {
    return {
      title: this.title,
    }
  },
  props: {
    msg: String
  },
  data() {
    return {
      active: true,
      period: null,
      config,
      isActive: true,
      timeOutIsSet: false,
      title: this.$i18n.t('profile.title'),
      timeOutToContinue: '',
      sessionId: '',
    }
  },
  mounted() {
    if (!(this.$route.params.courseId in this.course) || !this.lesson) {
      if (!(this.course && this.course.GROUPS)) {
        const params = this.$route.params.type
          ? { extNeedId: this.$route.params.needId } 
          : { needId: this.$route.params.needId };
        this.getCourse(params);
      }
    }

    this.startLesson();
    this.timeOutIsSet = false;
    this.isActive = true;
  },

  beforeDestroy() {
    this.stopPing()
  },

  beforeRouteLeave (to, from, next) {
    if (this.timeOutToContinue) {
      clearTimeout(this.timeOutToContinue)
    }

    next()
  },
  methods: {
    async startLesson() {
      this.setStartTime(new Date());
      const { courseId, id, type, ...need } = this.$route.params;
      let session = await api.startLessonSession(courseId, id, (type ? { extNeedId: need.needId } : { needId: need.needId }));

      if (session && session.data && session.data.error) {
        this.$router.push({
          name: 'course',
          params: {
            id: this.$router.currentRoute.params.courseId
          }
        });
      }

      if (session.data && this.active && session.data.result.id) {
        this.sessionId = session.data.result.id;
        this.active = true;
        this.startPing();
      }

      this.checkActive();
    },
    checkActive() {
      $('iframe#lesson_iframe').contents().find('body').bind('click keydown mousemove scroll mouseenter', () => {
        this.setStartTime(new Date());
      });
    },
    startPing() {
      if (this.active) {
        this.period = setInterval(this.ping, this.pingTime * 1000);
      }
    },
    stopPing() {
      clearInterval(this.period);
      this.period = null;
    },
    ping() {
      if (!this.startTime) {
        this.stopPing();
        return;
      }
      let time = new Date();
      const diff = (time.getTime() - this.startTime.getTime()) / 1000;

      if (diff > this.showNotActivePopupTime) {
        this.isActive = false;
        $('#modalLesson').modal('show');
        this.stopPing();

        if (!this.timeOutIsSet) {
          this.timeOutToContinue = setTimeout(() => {
            if (!this.isActive) {

              this.$router.push({
                name: 'course',
                params: {
                  id: this.$router.currentRoute.params.courseId
                }
              });
            }
          }, this.maxPingTime * 1000);
        }

        this.timeOutIsSet = true;
      } else {
        const session = api.ping(
          this.sessionId, 
          this.$route.params.courseId, 
          this.$route.params.id, 
          (this.$route.params.type ? { extNeedId: this.$route.params.needId} : { needId: this.$route.params.needId } )
        );
        session.then(response => {
          if (response.data && response.data.result && response.data.result.id) {
            this.sessionId = response.data.result.id
          }
        })
      }
    },
    continueLesson() {
      $('#modalLesson').modal('hide');
      this.setStartTime(new Date());
      this.isActive = true;
      this.timeOutIsSet = false;
      if (this.timeOutToContinue) {
        clearTimeout(this.timeOutToContinue)
      }
      this.startPing();
    },
    ...mapActions([
      'getCourse'
    ]),
    ...mapMutations([
      'setStartTime'
    ])
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    courseId() {
      return this.$route.params.courseId
    },
    nextLessonId() {
      if (!this.course || !this.course.GROUPS) {
        return null;
      }

      let materials = [];

      for (let group of this.course.GROUPS) {
        materials = materials.concat(group.MATERIALS)
      }

      materials = materials.concat(this.course.ROOT.MATERIALS);

      let isFind = false;

      for (let i in materials) {
        if (isFind) {
          if (materials[+i + 1] && materials[+i + 1].TYPE === 'lesson' && materials[+i + 1].SLIDES_FOLDER) {
            return materials[+i + 1].ID;
          }
        }

        if (materials[i].ID === this.id) {
          isFind = true;

          if (materials[+i + 1] && materials[+i + 1].TYPE === 'lesson' && materials[+i + 1].SLIDES_FOLDER) {
            return materials[+i + 1].ID;
          }
        }
      }

      return null;
    },
    lesson() {
      if (!this.course.GROUPS) {
        return null;
      }

      for (let group of this.course.GROUPS) {
        for (let material of group.MATERIALS) {
          if (material.ID === this.id) {
            return material;
          }
        }
      }

      for (let material of this.course.ROOT.MATERIALS) {
        if (material.ID === this.id) {
          return material;
        }
      }

      return null;
    },
    lessonUrl() {
      let slidesFolder = "";
      
      if(this.lesson && this.isIspringType) {
        slidesFolder = this.lesson.SLIDES_FOLDER.endsWith('/') ? this.lesson.SLIDES_FOLDER : this.lesson.SLIDES_FOLDER + "/";
      }else{
        slidesFolder = this.lesson.SLIDES_FOLDER;
      }

      return slidesFolder;
    },
    isType() {
      return this.lesson && this.lesson.MATERIAL_TYPE;
    },
    isDocType() {
      return this.isType && this.lesson.MATERIAL_TYPE === 'document';
    },
    isPdfType() {
      return this.isType && this.lesson.MATERIAL_TYPE === 'pdf';
    },
    isIspringType() {
      return this.isType && this.lesson.MATERIAL_TYPE === 'ispring';
    },
    is360Type() {
      return this.isType && this.lesson.MATERIAL_TYPE === 'course360';
    },
    isVideoType() {
      return this.isType && this.lesson.MATERIAL_TYPE === 'video';
    },
    isAudioType() {
      return this.isType && this.lesson.MATERIAL_TYPE === 'sound';
    },
    isTextType() {
      return this.isType && this.lesson.MATERIAL_TYPE === 'text';
    },
    isYoutubeType() {
      return this.isType && this.lesson.MATERIAL_TYPE === 'youtube';
    },
    ...mapState([
      'course',
      'pingTime',
      'maxPingTime',
      'startTime',
      'showNotActivePopupTime',
      'courseRestricted',
    ]),
  }
}
